<!-- feature_part start-->
<section class="feature_part" id="facilities">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-xl-3 align-self-center">
                <div class="single_feature_text ">
                    <h2>Special <br> Facilities</h2>
                    <p>Eduction is the most imortant focus for us. To make actual growth of children we have all of the 
                        facilities provided here. We provide more friendly eduction environment for your children.
                    </p>
                    <!-- <a href="#" class="btn_1">Read More</a> -->
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="single_feature">
                    <div class="single_feature_part">
                        <span class="single_feature_icon"><i class="ti-user"></i></span>
                        <h4>Qualified Teachers</h4>
                        <p>We have the best and qualified teachers for every subject and for every class.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="single_feature">
                    <div class="single_feature_part">
                        <span class="single_feature_icon"><i class="ti-map-alt"></i></span>
                        <h4>Best Infrastructure</h4>
                        <p>We have our own school infrastructure which is situated in the heart of Nawagarh.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="single_feature">
                    <div class="single_feature_part single_feature_part_2">
                        <span class="single_service_icon style_icon"><i class="ti-car"></i></span>
                        <h4>Transportation</h4>
                        <p>We provide the transportation service, We make sure the safety of student as well.  
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- upcoming_event part start-->

