<!-- footer part start-->
<footer class="footer-area" id="footer">
    <div class="container mb-5">
        <div class="row justify-content-between">
            <div class="col-sm-6 col-md-4 col-xl-3">
                <div class="single-footer-widget footer_1">
                    <a href="index.html"> <img src="assets/images/logo.png" alt=""> </a>
                    <p>New Gurukul English Medium School</p>
                    <p>Feel free to reach us for any enquery. <br>
                        We are always here to help you. </p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-4">
                <div class="single-footer-widget footer_2">
                    <h4>Location</h4>
                    <div class="google-maps">
                        <iframe width="400" height="250"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.9417547557177!2d81.61270221483285!3d21.898318562899963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a284f7cf9a17a29%3A0x943b9358784ce4cc!2sNew%20Gurukul%20School%20Nawagarh!5e0!3m2!1sen!2sin!4v1677585680883!5m2!1sen!2sin"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-md-4">
                <div class="single-footer-widget footer_2">
                    <h4>Contact us</h4>
                    <div class="contact_info">
                        <p><span> Address :</span> Shankar Nagar, Nawagarh, Distirct - Bemetara, Chhattisgarh, India.
                        </p>
                        <p><span> Phone :</span> 94241-34349, 70004-63915, 79873-26824, 91656-24492</p>
                        <p><span> Email : </span> gurukulsnawagarh@gmail.com </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid" *ngIf="false">
        <div class="row">
            <div class="col-lg-12">
                <div class="copyright_part_text text-center">
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="footer-text m-0">
                                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                                Copyright &copy;
                                <script>document.write(new Date().getFullYear());</script> All rights reserved | This
                                template is made with <i class="ti-heart" aria-hidden="true"></i> by <a
                                    href="https://colorlib.com" target="_blank">Colorlib</a>
                                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer part end-->