import { Component, OnInit } from '@angular/core';
import { eventItem } from '../events/events.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }
  
  eventsArray: bannerItem[] = [
    {
      image: "assets/images/banner_images/building_2.jpg"
    },
    {
      image: "assets/images/banner_images/building_1.jpg"
    },
    {
      image: "assets/images/banner_images/annual_1.jpg"
    },
    {
      image: "assets/images/banner_images/annual_2.jpg"
    },
  ];

  constructor() { }

  ngOnInit(): void {
    var review = $('.banner_iner');
    if (review.length) {
      review.owlCarousel({
        autoHeight: true,
        items: 1,
        loop: true,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        nav: false,
        responsive: {
          0: {
            margin: 15,
          },
          600: {
            margin: 10,
          },
          1000: {
            margin: 10,
          }
        }
      });
    }
  }
}

export interface bannerItem {
  image : string;
} 