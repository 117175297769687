<!--::review_part start::-->
<section class="testimonial_part curriculum-activities mb-5" id="activities">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12">
                <div class="section_tittle text-center">
                    <p>Overall development of kids</p>
                    <h2>Curriculum Activities</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let i of eventsArray">
                        <div class="event-container">
                            <div class="image-container">
                                <img [src]="i.image" attr.alt="{{i.title}}">
                            </div>
                            <div class="event-title">
                                {{i.title}}
                            </div>
                        </div>
                    </ng-template>  
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--::blog_part end::-->


    