import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // $(window).scroll(function () {
    //   var window_top = $(window).scrollTop() + 1;
    //   if (window_top > 50) {
    //     $('.main_menu').addClass('menu_fixed animated fadeInDown');
    //   } else {
    //     $('.main_menu').removeClass('menu_fixed animated fadeInDown');
    //   }
    // });
  }

  scrollToElement(id: string) {
    $('.navbar-collapse').collapse('hide');
    const element = document.querySelector("#"+id);
    // if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const headerOffset = 10;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - headerOffset
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

}


