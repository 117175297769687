<!-- learning part start-->
<section class="principal-message" id="ourMessage">
    <div class="container">
        <div class="row align-items-sm-center align-items-lg-stretch">
            <div class="col-md-2 col-lg-2">
                <div class="learning_img">
                    <img class="img-border" src="assets/images/principal.jpg" alt="">
                    <div class="mt-2">
                        <strong>Principal</strong>
                        <p>Manindar Kaur Khurana</p>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-lg-10">
                <div class="learning_member_text">
                    <!-- <h5>History of school</h5> -->
                    <h2>From the Principal’s Desk</h2>
                    <p class="text-justify">
                        <i>"The Woods are lovely dark and deep,
                            But I have promises to keep,
                            and miles to go before I sleep." </i>
                        These inspiring words form the essence and spirit of team New Gurukul English Medium School. The
                        school in a semi-rural background, New Gurukul English Medium School strives to merge and
                        prepare a perfect concoction of modern innovations in the field of education and the rich
                        cultural heritage of our great nation India. We at NGEMS are committed to realizing the dream
                        envisaged by our Beacon and guiding force. <span *ngIf="showMoreP1">It gives us great satisfaction to see the little ones bask in the glory of the bright light
                            emanating from education. It has brought us immense pleasure to see the efforts of our children
                            culminate into the creation of our first maiden venture that is, Zest.
                            As the name suggests, Zest is an effort to give wings to our young minds. It is an attempt to
                            equip the children, so that they may soar the skies with their Zest of imagination and
                            creativity.
                            I hope that the forthcoming pages will unfold the untold and unknown aspects of our children,
                            who have great potential. So let’s begin the journey of talents.
                            A man with God is always in majority. May you all be with God and He with you all. </span> 
                            <a href="javascript:;" (click)="showMoreP1 = !showMoreP1">{{ showMoreP1 ? 'Show Less' : 'Show More' }}</a>
                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- learning part end-->

<!-- learning part start-->
<section class="principal-message director mt-5 pt-5">
    <div class="container">
        <div class="row align-items-sm-center align-items-lg-stretch">
            <div class="col-md-10 col-lg-10 order-sm-2">
                <div class="learning_member_text">
                    <!-- <h5>History of school</h5> -->
                    <h2>From the Director’s Desk</h2>
                    <p class="text-justify">
                        <i>"Pearls of Inspiration from the Beacon." </i>
                         New Gurukul English Medium School is not just a school; it is a dream come true though tons of
                        hard labor and foresight. Not long ago a dream was realized in the form of New gurukul English
                        medium school with a vision to provide the best in the field of education to the tiny seedlings
                        awaiting to blossom into a mighty tree. The school is deeply and sincerely committed to make a
                        difference in the way teaching learning process unfolds.  
                        <span *ngIf="showMoreP2">
                            Aa a resident on Nawagarh, we had always felt the need of a school that would cater to the emerging needs of the learner.
                            A new beginning had to be made that would usher the new era, a new dawn and with this vision
                        New Gurukul English Medium School was set up in a very healthy ambience and a very noble cause.
                            Innovation strategies are continuously evolved at NGEMS to churn out the best in a child. We are
                            Grateful to the almighty who chose us as a means to achieve the end of proliferating the light
                            of education. Today the world has shrunk into a small village and it becomes imperative for us
                            to provide our children a global curriculum. 
                            <br>
                            Every child is a unique creation of the God and so deserves special attention and care which is
                            provided at NGEMS. The School provides a perfect blend of modernity and our strong moral ethos.
                            We are confident of chiseling perfect global citizens, which is the need of the hour.
                            <br>
                            I take this opportunity to extend my heartfelt thanks to everyone who is attached in this
                            venture with us. 
                        </span>
                        <a href="javascript:;" (click)="showMoreP2 = !showMoreP2">{{ showMoreP2 ? 'Show Less' : 'Show More' }}</a>
                        
                    </p>
                </div>
            </div>
            <div class="col-md-2 col-lg-2 order-sm-10">
                <div class="learning_img">
                    <img class="img-border" src="assets/images/director.jpg" alt="">
                    <div class="mt-2">
                        <strong>Director</strong>
                        <p>Gajpal Singh Khurana</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- learning part end-->