<!--::header part start::-->
<header class="main_menu home_menu menu_fixed">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" href="index.html">
                        <img src="assets/images/logo.png" alt="logo">
                        <img class="ml-3" src="assets/images/header_name.png" alt="logo">
                    </a>

                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    
                    <div class="collapse navbar-collapse main-menu-item justify-content-end"
                        id="navbarSupportedContent">
                        <ul class="navbar-nav align-items-center">
                            <li class="nav-item active">
                                <a class="nav-link" href="javascript:;" (click)="scrollToElement('home')">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;"
                                    (click)="scrollToElement('ourMessage')">Our Message</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;" (click)="scrollToElement('about')">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;" (click)="scrollToElement('mission')">Our
                                    Mission</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;"
                                    (click)="scrollToElement('activities')">Activities</a>
                            </li>
                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Pages
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="single-blog.html">Single blog</a>
                                    <a class="dropdown-item" href="elements.html">Elements</a>
                                </div>
                            </li> -->

                            <li class="d-none d-lg-block">
                                <a class="btn_1" href="javascript:;"
                                    (click)="scrollToElement('footer')">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Header part end-->