<!-- banner part start-->
<section class="banner_slider" id="home">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let i of eventsArray">
            <div class="event-container">
                <div class="image-container">
                    <img [src]="i.image" attr.alt="{{i.title}}">
                </div>
            </div>
        </ng-template>  
    </owl-carousel-o>    
</section>

<section class="banner_part">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-xl-6">
                <div class="banner_text">
                    <div class="banner_text_iner">
                        <div class="animate__animated animate__fadeInDown animate__slow">
                            <h5>Education is the best outfit.</h5>
                            <h1>
                                <div>New Gurukul English Medium School</div>
                                <small>Humanity is education.</small>
                            </h1>
                            <p>Shankar Nagar, Nawagarh, Distirct - Bemetara, Chhattisgarh, India.</p>
                            <p>Reg. No. 32077/2015005/122025</p>
                        </div>
                        <div class="animate__animated animate__fadeInLeft animate__slow">
                            <a href="#" class="btn_1">Whatsapp</a>
                            <a href="#" class="btn_2">Call Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- banner part start-->