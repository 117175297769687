import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-parents-feedback',
  templateUrl: './parents-feedback.component.html',
  styleUrls: ['./parents-feedback.component.css']
})
export class ParentsFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var review = $('.textimonial_iner');
    if (review.length) {
      review.owlCarousel({
        items: 1,
        loop: true,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        nav: false,
        responsive: {
          0: {
            margin: 15,
          },
          600: {
            margin: 10,
          },
          1000: {
            margin: 10,
          }
        }
      });
    }
  }
}
