import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor() { }
  
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2,
      },
      940: {
        margin: 60,
        items: 2,
      }
    },
    nav: false
  }
  
  eventsArray: eventItem[] = [
    {
      title: "Annual Function",
      image: "assets/images/gallery/annual_1.jpg"
    },
    {
      title: "Computer Education",
      image: "assets/images/gallery/computer_class.jpg"
    },
    {
      title: "Cultural Event",
      image: "assets/images/gallery/cultural_event.jpg"
    },
    {
      title: "Drawing Class",
      image: "assets/images/gallery/drawing.jpg"
    },
    {
      title: "Games",
      image: "assets/images/gallery/games_for_kids.jpg"
    },
    {
      title: "Prize Distribution",
      image: "assets/images/gallery/prize_distribution.jpg"
    },
    {
      title: "Tree Plantation",
      image: "assets/images/gallery/tree_plantation.jpg"
    },
    {
      title: "Yoga and Exercise",
      image: "assets/images/gallery/yoga_exercise.jpg"
    },
    {
      title: "Educational Tour",
      image: "assets/images/gallery/school_tour_stadium.jpg"
    },
    
  ];

  ngOnInit(): void {

  }

}

export interface eventItem {
  title: string;
  image: string;
}