import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/header/header.component';
import { BannerComponent } from './components/banner/banner.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { OurStaffsComponent } from './components/our-staffs/our-staffs.component';
import { ParentsFeedbackComponent } from './components/parents-feedback/parents-feedback.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { EventsComponent } from './components/events/events.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared-module/shared.module';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { MissionComponent } from './components/mission/mission.component';
import { HistoryComponent } from './components/history/history.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    OurTeamComponent,
    FacilitiesComponent,
    WhyUsComponent,
    GalleryComponent,
    OurStaffsComponent,
    ParentsFeedbackComponent,
    StatisticsComponent,
    EventsComponent,
    FooterComponent,
    WelcomeComponent,
    MissionComponent,
    HistoryComponent
  ],
  imports: [
    CarouselModule ,
    SharedModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
