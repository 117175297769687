<!-- learning part start-->
<section class="learning_part" id="about">
    <div class="container">
        <div class="row align-items-sm-center align-items-lg-stretch">
            <div class="col-md-7 col-lg-7">
                <div class="learning_img">
                    <img src="assets/images/learning_img.png" alt="">
                </div>
            </div>
            <div class="col-md-5 col-lg-5">
                <div class="learning_member_text">
                    <h5>History of school</h5>
                    <h2>Our journey</h2>
                    <p>Today, The New Gurukul English Medium School is not an affront to the introduction of 
                        anyone, which was fully supported by the school family and people of the area. In 2015, 
                        The Director of the institute, Shri Gajpal Singh Khurana, laid the foundation of an excellent and 
                        simple English medium school in Nawagarh in view of the need for English medium education in Nawagarh 
                        and surrounding areas and he provided quality education to the children of Nawagarh region. 
                        Traced a long development journey of his to. From 2015 to present day, the school is continuously moving it's development path.</p>
                    <ul>
                        <!-- <li><span class="ti-pencil-alt"></span>Him lights given i heaven second yielding seas
                            gathered wear</li>
                        <li><span class="ti-ruler-pencil"></span>Fly female them whales fly them day deep given
                            night.</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- learning part end-->