<!-- learning part start-->
<section class="advance_feature learning_part our-mission" id="mission">
    <div class="container">
        <div class="row align-items-sm-center align-items-xl-stretch">
            <div class="col-md-6 col-lg-6">
                <div class="learning_member_text">
                    <h5>Objective</h5>
                    <h2>Our Mission</h2>
                    <p>The only objective of our school is to provide quality education to the children of Nawagarh and surrounding areas by giving them less fees and more facilities so that every child's emotional, physical, intellectual development and good character is created so that the children can be a part of society and the nation. They will be able to contribute their best for development.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="learning_img">
                    <img src="assets/images/goal.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- learning part end-->